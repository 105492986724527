<template>
  <div class="Report__Entity">
    <Entity
      ref="entity"
      entity-type="DriverContract"
      :entity-id="contractId"
      :innerComponent="innerComponent"
      innerComponentTitle="פרטי חוזה נהג"
      title="חוזה שכירות לנהג"
      back-button-text="רשימת חוזי נהגים"
      back-button-url="drivers"
      :widgets="widgets"
      :preview="preview"
      :actions="null"
      :read-only="readOnly"
      :entityReady="entityReady"
      :activities="[]"
      @onFormSave="onFormSave"
      @onFormDiscard="onFormDiscard"
    >
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">לוחית רישוי:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Vehicle.Name }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">נהג:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Driver.Name }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">תאריך תחילה:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            ><FieldDateHourInline :value="StartDate"
          /></span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">תאריך סיום:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            ><FieldDateHourInline :value="EndDate"
          /></span>
        </div>
        <!-- <div class="Accident__Entity__Details__BaseDetails__Stage">
            <span class="ltr">תאריך יצירה:</span>
            <span class="Accident__Entity__Details__BaseDetails__Stage__Value
                        value has-text-weight-bold"
              >{{CreateDate | date}}</span
            >
        </div> -->
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">תאריך עדכון:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            ><FieldDateHourInline :value="UpdateDate"
          /></span>
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
import Entity from "@/components/Entity.vue";
import DriverContractEntityDetails from "@/components/DriverContractEntityDetails.vue";
import moment from "moment";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import FieldDateHourInline from "@/components/Fields/FieldDateHourInline.vue";
import WidgetService from "../services/WidgetService";
import DriverService from "../services/DriverService";
import VehicleService from "../services/VehicleService";

const { mapFields } = createHelpers({
  getterType: "driverContract/getField",
  mutationType: "driverContract/updateField",
});

export default {
  components: {
    Entity,
    FieldDateHourInline,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    contractId() {
      return this.$route.params.id || this.id;
    },
    widgets() {
      return [
        {
          ...WidgetService.Vehicle,
          namespace: "driverContract",
          fetch: true,
          delegate: VehicleService.getVehicle,
        },
        { ...WidgetService.Driver, namespace: "driverContract", fetch: true, delegate: DriverService.getDriver },
        {
          ...WidgetService.DriverContractDocuments,
          namespace: "driverContract",
          documentType: "TransferHtml",
          title: "הצהרת נהג",
          open: true
        },
      ];
    },
    innerComponent() {
      return DriverContractEntityDetails;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    ...mapFields([
      "StartDate",
      "UpdateDate",
      "CreateDate",
      "EndDate",
      "Vehicle",
      "Driver",
      "HasTransferHtml",
    ]),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
    };
  },
  async created() {
    this.init();
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.contractId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      DriverService.updateContract(this.Driver.Id, {
        ...model,
        Id: +this.contractId,
        VehicleId: +this.Vehicle.Id,
      })
        .then(() => {
          this.init(refresh);
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch((err) => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: err.response?.data?.ErrorMessage
              ? err.response?.data?.ErrorMessage
              : "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          saving(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "driverContract/loadContract",
    }),
  },
};
</script>

<style>
</style>
