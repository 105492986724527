var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ReportEntityDetails__Component" },
    [
      _c("FieldInlineDateTime", {
        attrs: { label: "תאריך התחלה", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.StartDate,
          callback: function($$v) {
            _vm.$set(_vm.model, "StartDate", $$v)
          },
          expression: "model.StartDate"
        }
      }),
      _c("FieldInlineDateTime", {
        attrs: { label: "תאריך סיום", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.EndDate,
          callback: function($$v) {
            _vm.$set(_vm.model, "EndDate", $$v)
          },
          expression: "model.EndDate"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }