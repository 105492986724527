<template>
  <div class="ReportEntityDetails__Component">
    <FieldInlineDateTime
      label="תאריך התחלה"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.StartDate"
    />

    <FieldInlineDateTime
      label="תאריך סיום"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.EndDate"
    />
  </div>
</template>

<script>
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "DriverContractEntityDetails",
  components: {
    FieldInlineDateTime,
  },
  props: ["readOnly"],
  data() {
    return {
      model: {
        StartDate: null,
        EndDate: null,
      },
    };
  },
  computed: {
    ...mapGetters("driverContract", ["form"]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === "function") {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) {
            return diff1;
          }
        }
        if (typeof o1[key] === "number") {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (
          o1[key] &&
          typeof o1[key] === "object" &&
          typeof o1[key].getMonth !== "function"
        ) {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit("onFormChange", this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit("onFormSave", {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeReportDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations("report", ["storeReportDetails"]),
  },
};
</script>

<style>
</style>
